export function formatDuration(
  durationInSeconds: number,
  format: "SHORT" | "LONG"
): string {
  const date = new Date(0);
  date.setSeconds(durationInSeconds);
  const timeString = date
    .toISOString()
    .replace(/.*(\d{2}:\d{2}:\d{2}).*/, "$1");
  const [hours, minutes, seconds] = timeString.split(":").map(Number);

  switch (format) {
    case "SHORT": {
      const short = `${String(minutes)}:${String(seconds).padStart(2, "0")}`;
      return short;
    }
    case "LONG": {
      let long = "";
      if (hours) long += `${hours}h `;
      if (minutes) long += `${minutes}m `;
      if (seconds) long += `${seconds}s`;
      return long;
    }
    default:
      throw new Error("Unsupported duration format");
  }
}
