import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { AnimatePresence, motion } from "framer-motion";
import { blobRepo } from "heirloom-api/BlobRepo";
import { LinkRecordingRepository } from "heirloom-api/repositories/link-recording";
import { useTranslation } from "next-i18next";
import { createMediaRecorder } from "services/media-recorder.service";
import { RecorderStates, useRecorderState } from "state/useRecorderState";

import HLButton from "./HLButton";
import { TRANSITION_DURATION_FAST } from "./HLMain";
interface RecordControlsProps {
  userMediaStream: MediaStream | null;
  setUserMediaStream: Dispatch<SetStateAction<MediaStream | null>>;
  previewURL: string | null;
  setStartSession: Dispatch<SetStateAction<boolean>>;
  linkId: string;
  linkRecordingRepo: LinkRecordingRepository;
  cameraReady: boolean;
  startRecordingDurationTimer: () => void;
  stopRecordingDurationTimer: () => number;
  resetRecordingDurationTimer: () => void;
  recordingLimitSec: number;
  duration: number;
  videoRecorderRef: MutableRefObject<MediaRecorder | null>;
  videoMimeType: string;
}

function RecordControls({
  userMediaStream,
  setUserMediaStream,
  previewURL,
  setStartSession,
  linkId,
  linkRecordingRepo,
  cameraReady,
  startRecordingDurationTimer,
  stopRecordingDurationTimer,
  resetRecordingDurationTimer,
  recordingLimitSec,
  duration,
  videoRecorderRef,
  videoMimeType,
}: RecordControlsProps) {
  const recordControlVariants = {
    hide: { opacity: 0, transition: { duration: TRANSITION_DURATION_FAST } },
    show: {
      opacity: 1,
      transition: {
        duration: TRANSITION_DURATION_FAST,
      },
    },
    moveDown: { y: 20, transition: { duration: TRANSITION_DURATION_FAST } },
    moveUp: {
      y: 0,
      transition: {
        duration: TRANSITION_DURATION_FAST,
      },
    },
  };

  const { recorderState, setRecorderState } = useRecorderState();
  const { t } = useTranslation("common");
  const showOneMinuteLeft =
    recordingLimitSec - 60 <= duration && duration < recordingLimitSec - 55;

  const showRemaining10Seconds = recordingLimitSec - 10 <= duration;

  const onCancelCountdown = () => {
    setRecorderState(
      previewURL === null ? RecorderStates.READY : RecorderStates.FINISHED
    );
  };

  const onStartRecording = async () => {
    if (
      recorderState !== RecorderStates.READY &&
      recorderState !== RecorderStates.FINISHED
    ) {
      return;
    }
    resetRecordingDurationTimer();

    let userStream = userMediaStream;
    // if we're starting from the "Re-Record" screen, we want to hide the existing preview
    if (userStream === null) {
      userStream = await navigator.mediaDevices.getUserMedia({
        video: {
          frameRate: 30,
        },
        audio: true,
      });
      setUserMediaStream(userStream);
    }

    const recorder = createMediaRecorder(userStream, videoMimeType);

    videoRecorderRef.current = recorder;

    setRecorderState(RecorderStates.STARTING);
  };

  useEffect(() => {
    const onFinished = async () => {
      stopRecordingDurationTimer();
    };

    if (recorderState === RecorderStates.FINISHED) {
      onFinished();
    }
  }, [recorderState]);

  useEffect(() => {
    const record = async () => {
      await blobRepo.clearBlob();
      await blobRepo.startBlob(videoMimeType);

      (videoRecorderRef.current as MediaRecorder).start(1000);
      startRecordingDurationTimer();
    };

    if (recorderState === RecorderStates.RECORDING) {
      record();
    }
  }, [
    recorderState,
    startRecordingDurationTimer,
    videoMimeType,
    videoRecorderRef,
  ]);

  const [progressPercent, setProgressPercent] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState("");
  const onUpload = async () => {
    setRecorderState(RecorderStates.UPLOADING);

    try {
      const linkRecording = await linkRecordingRepo.createRecording(linkId, {
        createdOn: Date.now(),
      });

      await linkRecordingRepo.uploadVideoFromBytes(
        linkId,
        linkRecording.id as string,
        await blobRepo.getBlob(),
        (progress) => {
          setProgressPercent(progress);
        }
      );
    } catch (e) {
      if (e instanceof Error) {
        setErrorMessage(e.message);
      }
    }

    await blobRepo.clearBlob();

    setRecorderState(RecorderStates.UPLOADED);
  };

  return (
    <div
      id="record-controls"
      className="max-w-[600px] w-full mt-6 flex flex-col"
    >
      <AnimatePresence initial={false} exitBeforeEnter>
        {recorderState === RecorderStates.READY ||
        recorderState === RecorderStates.STARTING ? (
          <motion.p
            id="record-control-instruction-text"
            className="text-spruce-60 font-inter"
            variants={recordControlVariants}
            initial="hide"
            animate="show"
            exit="hide"
          >
            {t("web-rec-ready-description")}
          </motion.p>
        ) : null}
        {recorderState === RecorderStates.FINISHED ? (
          <motion.p
            id="record-control-instruction-text"
            className="text-spruce-60 font-inter"
            variants={recordControlVariants}
            initial="hide"
            animate="show"
            exit="hide"
          >
            {t("web-rec-preview-description")}
          </motion.p>
        ) : null}
        {recorderState === RecorderStates.RECORDING && showOneMinuteLeft ? (
          <motion.p
            key="one-min-left"
            className="text-spruce-60 font-inter text-center"
            variants={recordControlVariants}
            initial={["hide", "moveDown"]}
            animate={["show", "moveUp"]}
            exit={["hide", "moveDown"]}
          >
            {t("web-rec-active-message-one-min-stop")}
          </motion.p>
        ) : null}
        {recorderState === RecorderStates.RECORDING &&
        showRemaining10Seconds ? (
          <motion.p
            key="10-sec-left"
            className="text-spruce-60 font-inter text-center"
            variants={recordControlVariants}
            initial={["hide", "moveDown"]}
            animate={["show", "moveUp"]}
            exit={["hide", "moveDown"]}
          >
            {t("web-rec-active-message-countdown-stop", {
              count: recordingLimitSec - duration,
            })}
          </motion.p>
        ) : null}
      </AnimatePresence>
      <div className="flex pt-6 ">
        <AnimatePresence initial={false} exitBeforeEnter>
          {recorderState === RecorderStates.READY ? (
            <motion.div
              key="start"
              className="flex w-full justify-between gap-4"
              variants={recordControlVariants}
              initial="hide"
              animate="show"
              exit="hide"
            >
              <HLButton
                title={t("web-rec-ready-action-secondary")}
                onClick={() => setStartSession(false)}
                buttonAnalyticsId="back-btn"
              />
              <HLButton
                highEmphasis
                disabled={userMediaStream === null || !cameraReady}
                title={t("web-rec-ready-action-primary")}
                onClick={onStartRecording}
                buttonAnalyticsId="start-recording-btn"
                ariaAttributes={{
                  "aria-label":
                    "Press Enter to start recording your Photo Story. You will have 3 seconds before recording begins.",
                  "aria-describedby": "record-control-instruction-text",
                }}
              />
            </motion.div>
          ) : null}
          {recorderState === RecorderStates.STARTING ? (
            <motion.div
              key="starting"
              className="h-12 w-full bg-spruce-10"
              variants={recordControlVariants}
              initial="hide"
              animate="show"
              exit="hide"
            >
              <HLButton
                title={t("web-rec-countdown-action-secondary")}
                onClick={onCancelCountdown}
                buttonAnalyticsId="cancel-recording-btn"
                ariaAttributes={{
                  "aria-describedby": "record-control-instruction-text",
                }}
              />
            </motion.div>
          ) : null}
          {recorderState === RecorderStates.RECORDING ? (
            <motion.div
              key="recording"
              className="h-12 w-full bg-spruce-10"
              variants={recordControlVariants}
              initial="hide"
              animate="show"
              exit="hide"
            >
              <HLButton
                title={t("web-rec-active-action-secondary")}
                onClick={() =>
                  (videoRecorderRef.current as MediaRecorder).stop()
                }
                buttonAnalyticsId="stop-recording-btn"
              />
            </motion.div>
          ) : null}
          {recorderState === RecorderStates.FINISHED ? (
            <motion.div
              key="finished"
              className="flex w-full justify-between gap-4"
              variants={recordControlVariants}
              initial="hide"
              animate="show"
              exit="hide"
            >
              <HLButton
                title={t("web-rec-preview-action-secondary")}
                onClick={onStartRecording}
                buttonAnalyticsId="rerecord-recording-btn"
                ariaAttributes={{
                  "aria-label":
                    "Press Enter to start recording your Photo Story. You will have 3 seconds before recording begins.",
                }}
              />
              <HLButton
                highEmphasis
                title={t("web-rec-preview-action-primary")}
                onClick={onUpload}
                buttonAnalyticsId="upload-recording-btn"
                ariaAttributes={{
                  "aria-describedby": "record-control-instruction-text",
                }}
              />
            </motion.div>
          ) : null}
          {recorderState === RecorderStates.UPLOADING ? (
            <motion.div
              key="uploading"
              className="flex flex-col w-full justify-center"
              variants={recordControlVariants}
              initial="hide"
              animate="show"
              exit="hide"
            >
              <div className="relative py-3">
                <div
                  id="loadingBackgroundBar"
                  className="h-1 rounded-sm absolute left-0 top-0 bg-spruce-30 w-full"
                />
                <div
                  id="loadingProgressBar"
                  tabIndex={0}
                  className="h-1 rounded-sm absolute left-0 top-0 bg-yellow-70 transition-all"
                  style={{ width: `${progressPercent}%` }}
                  role="progressbar"
                  aria-valuenow={progressPercent}
                  aria-valuemin={0}
                  aria-valuemax={100}
                  aria-valuetext="Uploading video..."
                  aria-live="assertive"
                  aria-describedby="upload-progress-instruction"
                />
              </div>
              <p className="font-inter font-semibold text-spruce-60 mt-3.5 text-center">
                {errorMessage || t("web-uploading-description")}
              </p>
            </motion.div>
          ) : null}
        </AnimatePresence>
      </div>
    </div>
  );
}

export default RecordControls;
