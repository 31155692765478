/* eslint-disable @next/next/no-img-element */
import {
  Dispatch,
  ReactEventHandler,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { RecordCountdown } from "components/RecordCountdown";
import { RecordingDuration } from "components/RecordingDuration";
import { AnimatePresence, motion } from "framer-motion";
import { RecorderStates, useRecorderState } from "state/useRecorderState";
import styles from "styles/spinner.module.css";

import {
  Dimensions,
  TRANSITION_DURATION_FAST,
  TRANSITION_DURATION_NORMAL,
} from "./HLMain";

interface PictureInPictureProps {
  startSession: boolean;
  animationCompleted: boolean;
  userMediaStream: MediaStream | null;
  setUserMediaStream: Dispatch<SetStateAction<MediaStream | null>>;
  previewURL: string | null;
  cameraReady: boolean;
  setCameraReady: Dispatch<SetStateAction<boolean>>;
  duration: number;
}

export default function PictureInPicture({
  startSession,
  animationCompleted,
  userMediaStream,
  setUserMediaStream,
  previewURL,
  cameraReady,
  setCameraReady,
  duration,
}: PictureInPictureProps) {
  const pipVariants = {
    hide: {
      x: "calc(110% + 4rem)",
      transition: {
        duration: TRANSITION_DURATION_NORMAL,
      },
    },
    show: {
      x: 0,
      transition: {
        duration: TRANSITION_DURATION_NORMAL,
      },
    },
    hideOverlay: {
      opacity: 0,
      transition: {
        duration: TRANSITION_DURATION_FAST,
      },
    },
    showOverlay: {
      opacity: 1,
      transition: {
        duration: TRANSITION_DURATION_FAST,
      },
    },
    scale: (recorderState: keyof typeof RecorderStates) => ({
      scale:
        recorderState === RecorderStates.UPLOADING
          ? 0.5
          : recorderState !== RecorderStates.FINISHED
          ? 0.75
          : 1,
      transition: {
        duration: TRANSITION_DURATION_NORMAL,
      },
    }),
  };

  const durationPillVariants = {
    hide: {
      opacity: 0,
      transition: {
        duration: TRANSITION_DURATION_NORMAL,
      },
    },
    show: {
      opacity: 1,
      transition: {
        duration: TRANSITION_DURATION_NORMAL,
      },
    },
  };

  const recorderState = useRecorderState((state) => state.recorderState);
  const showPip = startSession && animationCompleted && cameraReady;

  const [showPreview, setShowPreview] = useState(false);
  const viewfinderRef = useRef<HTMLVideoElement>(null);
  const previewRef = useRef<HTMLVideoElement>(null);
  const [videoDimensions, setVideoDimensions] = useState<Dimensions>({
    width: 0,
    height: 0,
  });

  const onViewfinderLoaded: ReactEventHandler<HTMLVideoElement> = (event) => {
    console.debug("viewfinder loaded");

    setShowPreview(false);
    setCameraReady(true);

    const videoEl = event.target as HTMLVideoElement;

    /**
     * if we haven't set video dimensions so far, do it now
     *
     * when this component is renderered against after the first load,
     * ie. when pressing "Re-Record",
     * sometimes event.target height and width are 0 here.
     *
     * by only saving videoDimensions if we haven't yet (if either value is equal to 0),
     * we avoid saving new 0 values and losing our previously-saved dimensions
     */
    if ([videoDimensions.width, videoDimensions.height].includes(0)) {
      setVideoDimensions({
        width: videoEl.offsetWidth,
        height: videoEl.offsetHeight,
      });
    }
  };

  const onPreviewLoaded: ReactEventHandler<HTMLVideoElement> =
    useCallback(() => {
      if (recorderState !== RecorderStates.FINISHED) {
        return;
      }

      setShowPreview(true);

      if (
        userMediaStream !== null &&
        recorderState === RecorderStates.FINISHED
      ) {
        console.debug("stopping media stream...");
        userMediaStream.getTracks().forEach((track) => track.stop());
        setUserMediaStream(null);
      }
    }, [setShowPreview, setUserMediaStream, userMediaStream, recorderState]);

  const pipContainerDimensions = useMemo(() => {
    if (videoDimensions.height === 0 && videoDimensions.width === 0) {
      return;
    }

    return {
      width: `${videoDimensions.width}px`,
      height: `${videoDimensions.height}px`,
    };
  }, [videoDimensions]);

  // if we're starting recording and we have a preview video ref, let's pause the video
  // let's also pause and rewind if we're uploading
  useEffect(() => {
    if (!previewRef.current) {
      return;
    }

    if (recorderState === RecorderStates.STARTING) {
      previewRef.current.pause();
    }

    // if we're in the finished state and already have a preview URL,
    // we're accessing a preview video we've already created before.
    // in this case, let's show the preview video immediately.
    if (recorderState === RecorderStates.FINISHED && previewURL) {
      setShowPreview(true);
    }

    if (recorderState === RecorderStates.UPLOADING) {
      previewRef.current.pause();
      previewRef.current.currentTime = 0;
    }
  }, [recorderState, previewRef, previewURL, setShowPreview]);

  // here we want to dynamically set viewfinder.srcObject in response to UI events
  useEffect(() => {
    if (!viewfinderRef.current) {
      return;
    }

    /**
     * if we are entering a state where the viewfinder is newly visible,
     * (either READY if coming from intro screen or STARTING if coming from re-record),
     * we want to set srcObject if it has not already been set
     */
    if (
      recorderState === RecorderStates.STARTING ||
      recorderState === RecorderStates.READY
    ) {
      if (viewfinderRef.current.srcObject === null) {
        viewfinderRef.current.srcObject = userMediaStream;
      }
    }

    /**
     * if we are done recording (in FINISHED state), detach the srcObject
     * so that `onViewfinderLoaded` will fire again once it's reattached
     */
    if (recorderState === RecorderStates.FINISHED) {
      viewfinderRef.current.srcObject = null;
    }
  }, [recorderState, userMediaStream]);

  return (
    <motion.div
      data-motion="position"
      className="top-4 right-4 absolute w-[40vw] md:w-[15vw] min-w-[155px] h-[30vh]"
      layout
      aria-hidden={!showPip}
    >
      <motion.div
        data-motion="translate"
        variants={pipVariants}
        initial="hide"
        animate={showPip ? "show" : "hide"}
        exit="hide"
      >
        <motion.div
          data-motion="scale"
          id="pipContainer"
          key="viewfinderVideo"
          variants={pipVariants}
          className="relative rounded-2xl z-10 max-w-full shadow-lg overflow-hidden h-full w-full"
          style={{
            originX: "100%",
            originY: "0%",
            ...pipContainerDimensions,
          }}
          initial={{ scale: 0.75 }}
          custom={recorderState}
          animate="scale"
        >
          <AnimatePresence initial={false}>
            {recorderState === RecorderStates.STARTING ? (
              <RecordCountdown variants={pipVariants} />
            ) : null}
            <video
              onLoadedData={onViewfinderLoaded}
              key="viewfinder"
              className={`object-contain z-20 max-h-full ${
                showPreview ? "hidden" : ""
              } ${
                recorderState === RecorderStates.FINISHED && !showPreview
                  ? "blur-sm"
                  : ""
              }`}
              style={{
                transform: "rotateY(180deg)",
                WebkitTransform: "rotateY(180deg)",
              }}
              autoPlay
              muted
              playsInline
              ref={viewfinderRef}
            ></video>
            {recorderState === RecorderStates.FINISHED && !showPreview ? (
              <div className="absolute top-0 left-0 h-full w-full bg-spruce-10-60 flex items-center justify-center z-30">
                <div
                  className={styles.sp_circle}
                  style={{ height: "32px", width: "32px" }}
                />
              </div>
            ) : null}
            <video
              key="preview"
              onLoadedData={onPreviewLoaded}
              className={`object-contain z-20 ${!showPreview ? "hidden" : ""} ${
                recorderState === RecorderStates.FINISHED && !showPreview
                  ? "blur-sm"
                  : ""
              }`}
              autoPlay
              playsInline
              controls={recorderState === RecorderStates.FINISHED}
              ref={previewRef}
              src={previewURL as string}
            ></video>
            {recorderState === RecorderStates.RECORDING ? (
              <motion.div
                key="duration-pill"
                variants={durationPillVariants}
                initial="hide"
                animate="show"
                exit="hide"
              >
                <RecordingDuration
                  isRecording={recorderState === RecorderStates.RECORDING}
                  duration={duration}
                />
              </motion.div>
            ) : null}
          </AnimatePresence>
        </motion.div>
      </motion.div>
    </motion.div>
  );
}
