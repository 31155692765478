import create from "zustand";

export enum RecorderStates {
  INITIALIZING = "INITIALIZING",
  READY = "READY",
  STARTING = "STARTING",
  RECORDING = "RECORDING",
  FINISHED = "FINISHED",
  UPLOADING = "UPLOADING",
  UPLOADED = "UPLOADED",
}

interface RecorderStateStore {
  recorderState: keyof typeof RecorderStates;
  setRecorderState: (newState: keyof typeof RecorderStates) => void;
}

export const useRecorderState = create<RecorderStateStore>((set) => {
  return {
    recorderState: RecorderStates.INITIALIZING,
    setRecorderState: (newState) => {
      set({
        recorderState: newState,
      });
    },
  };
});
