import localforage from 'localforage';
import { Workbox } from 'workbox-window';

export class BlobRepository {
  private workbox!: Workbox;

  private blobSize = 0;
  private isFull = false;
  private isWriting = false;
  private chunkQueue: Array<Blob | Uint8Array> = [];
  private memoryChunks: Array<Blob | Uint8Array> = [];
  private chunkCount = 0;
  private chunkWriter: Promise<void> | null = null;

  async initialize() {
    this.workbox = new Workbox('/service-worker.js');
    if (!('serviceWorker' in navigator)) {
      throw new Error('The service worker API is not available');
    }
    this.workbox.addEventListener('installed', (event) => {
      if (!event.isUpdate) {
        console.debug('The service worker is installed');
      } else {
        console.debug('The service worker is installed and updated');
      }
    });

    this.workbox.addEventListener('controlling', (event) => {
      console.debug('The service worker is controlling');
    });
    this.workbox.addEventListener('activated', (event) => {
      console.debug('The service worker is activated');
    });
    this.workbox.addEventListener('waiting', (event) => {
      console.debug(
        `A new service worker has installed, but it can't activate` +
          `until all tabs running the current version have fully unloaded.`,
      );
    });

    const registration = await this.workbox.register();
    if (registration == null) {
      throw new Error('Failed to register with the service worker');
    }
    if (registration.installing) {
      console.debug('Service worker installing');
    } else if (registration.waiting) {
      console.debug('Service worker installed');
    } else if (registration.active) {
      console.debug('Service worker active');
    }
    const fillerDB = localforage.createInstance({
      name: 'filler',
    });
    await fillerDB.setItem('root', new Uint8Array(1024 * 1024));
    return true;
  }

  private async _writeChunks(): Promise<void> {
    try {
      this.isWriting = true;
      if (this.isFull) {
        throw new Error(`Can't write to a full store`);
      }
      while (this.chunkQueue.length > 0) {
        const chunk = this.chunkQueue.shift();
        if (chunk == null) {
          break;
        }
        try {
          this.blobSize += chunk instanceof Blob ? chunk.size : chunk.length;

          // we set up a race condition here to ensure we're not stuck indefinitely at the "requesting more storage" dialog
          // ideally we'd want to cancel the localforage action, but localforage doesn't support transactions
          // TODO: swap localforage out for https://dexie.org/, to allow for cancellable indexeddb transactions
          await Promise.race([
            Promise.all([
              localforage.setItem(`chunk-${this.chunkCount}.end`, this.blobSize),
              localforage.setItem(`chunk-${this.chunkCount}`, chunk),
            ]),
            new Promise((resolve, reject) => setTimeout(() => reject(new Error('localforage write timed out')), 15000)),
          ]);
          console.debug(
            `Wrote chunk ${this.chunkCount}. Total blob size: ${Math.ceil(this.blobSize / (1024 * 1024))}MB`,
          );
          this.chunkCount++;
        } catch (err) {
          // We want to avoid the unlikely case in which the chunk goes in but not its index value.
          // That would cause the worker to fail when handling range request, and it's tedious to handle
          // this situation in the worker.
          await Promise.all([
            localforage.removeItem(`chunk-${this.chunkCount}.end`),
            localforage.removeItem(`chunk-${this.chunkCount}`),
          ]);
          // We don't want to lose that chunk, it failed to be written in the persistent store
          this.chunkQueue.unshift(chunk);
          // Let the error be handled in the broader exception handler
          throw err;
        }
      }
      // This has to happen before any await statements, otherwise a new worker may fail to be
      // created by the addBlobChunk while the current worker is cleaning up.
      this.isWriting = false;
      await localforage.setItem('chunk-count', this.chunkCount);
    } catch (err) {
      this.isWriting = false;
      this.isFull = true;

      console.error(`Failed to write chunk ${this.chunkCount}`, err);
      console.error(`There are ${this.chunkQueue.length} pending chunks`);

      try {
        // The way indexed DB handles storage (and its limit) is a bit odd. This ensures that we get some space
        // immediately.
        await this._deleteDatabase('filler');
        await localforage.setItem('chunk-count', this.chunkCount);
      } catch (err) {
        console.error(`Failed to update the chunk count, some chunks may be missing at the end`);
      }

      while (this.chunkQueue.length !== 0) {
        console.debug('Pushing out a chunk to the worker');
        const memoryChunk = this.chunkQueue.shift();

        await this.workbox.messageSW({
          type: 'POST_MEMORY_CHUNKS',
          chunks: [memoryChunk],
        });

        this.memoryChunks.push(memoryChunk as Blob | Uint8Array);
      }
    } finally {
      console.debug('The writer is done');
    }
  }

  async startBlob(mimeType: string): Promise<void> {
    const chunkCount = await localforage.getItem('chunk-count');
    if (chunkCount != null) {
      throw new Error('There is already a blob in the repository, call clearBlob() first');
    }
    await localforage.setItem(`blob-type`, mimeType);
    await this.workbox.messageSW({
      type: 'START',
    });
  }

  async _deleteDatabase(name: string) {
    const result = window.indexedDB.deleteDatabase(name);
    await new Promise<void>((resolve) => {
      result.onsuccess = () => resolve();
    });
  }

  async clearBlob(): Promise<void> {
    await this._deleteDatabase('localforage');
    this.blobSize = 0;
    this.isFull = false;
    this.isWriting = false;
    this.chunkQueue = [];
    this.chunkCount = 0;
    this.chunkWriter = null;
  }

  addBlobChunk(data: Blob | Uint8Array): boolean {
    this.chunkQueue.push(data);
    if (this.isWriting) {
      return !this.isFull;
    }
    this.chunkWriter = this._writeChunks();
    return !this.isFull;
  }

  async getBlobUrl(): Promise<string> {
    return (await this.workbox.messageSW({ type: 'GET_URL' })) as string;
  }

  async getBlob(): Promise<Blob> {
    const blobType = (await localforage.getItem('blob-type')) as string;
    const chunkCount = (await localforage.getItem('chunk-count')) as number;

    const chunkPromises = [...Array((chunkCount as number) + 1).keys()].map((index) => {
      return localforage.getItem<Blob>(`chunk-${index}`);
    });
    const blobData = (await Promise.all(chunkPromises)) as Array<Blob | Uint8Array>;

    while (this.memoryChunks.length > 0) {
      blobData.push(this.memoryChunks.shift() as Blob | Uint8Array);
    }

    return new Blob(blobData, { type: blobType });
  }

  async wait(): Promise<void> {
    if (this.chunkWriter == null) {
      return;
    }
    await this.chunkWriter;
  }
}
