import { useCallback } from "react";

export enum Events {
  "click" = "Click",
  "recordEnd" = "RecordEnd",
  "upload" = "Upload",
}

interface TrackPayload {
  [key: string]: unknown;
}

export interface AnalyticsService {
  track: (event: Events, payload?: TrackPayload) => void;
}

interface SegmentAnalyticsService {
  track: (event: Events, payload: TrackPayload) => void;
}
class AnalyticsServiceImpl implements AnalyticsService {
  constructor(private analyticsService: SegmentAnalyticsService) {}
  track(event: Events, payload: TrackPayload = {}): void {
    return this.analyticsService.track(event, payload);
  }
}

export const useAnalytics = () => {
  if (typeof window !== "undefined") {
    // @ts-ignore window.analytics undefined below
    return new AnalyticsServiceImpl(window.analytics);
  }
};

export const useAnalyticsTimer = (
  unit: "ms" | "seconds" = "ms"
): [() => number, (startTime: number) => number] => {
  const start = useCallback((): number => {
    return Date.now();
  }, []);
  const stop = useCallback(
    (startTime: number): number => {
      const duration = Date.now() - startTime;
      return unit === "seconds" ? duration / 1000 : duration;
    },
    [unit]
  );

  return [start, stop];
};
