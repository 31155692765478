import { FirestoreInstance, UploadTask, UploadTaskSnapshot } from '../../providers/firebase';
import { CloudStorageInstance } from '../../providers/firebase';
import { FirestoreRepository } from '../firestore.repository';

import {
  LinkRecording,
  LinkRecordingObserver,
  LinkRecordingRepository,
  LinkRecordingUnsubscriber,
  RecordingUploadProgressCallback,
} from './link-recording.types';

export class FirestoreLinkRecordingRepository
  extends FirestoreRepository<LinkRecording>
  implements LinkRecordingRepository
{
  constructor(firestore: FirestoreInstance, private storage: CloudStorageInstance) {
    super(firestore);
    this.storage = storage;
  }

  private static getCollectionPath(linkId: string) {
    return `links/${linkId}/recordings`;
  }

  private static getEntityPath(linkId: string, recordingId: string) {
    return `${FirestoreLinkRecordingRepository.getCollectionPath(linkId)}/${recordingId}`;
  }

  private static getStorageRecordingPath(linkId: string, recordingId: string): string {
    const linkRecordingPath = FirestoreLinkRecordingRepository.getEntityPath(linkId, recordingId);
    return `${linkRecordingPath}/video_original`;
  }

  createRecording(linkId: string, recording: LinkRecording): Promise<LinkRecording> {
    return super.create(FirestoreLinkRecordingRepository.getCollectionPath(linkId), recording);
  }

  getRecordingUrl(linkId: string, recordingId: string): Promise<string> {
    return this.storage
      .ref(FirestoreLinkRecordingRepository.getStorageRecordingPath(linkId, recordingId))
      .getDownloadURL();
  }

  getRecordings(linkId: string): Promise<LinkRecording[]> {
    return super.getAll(FirestoreLinkRecordingRepository.getCollectionPath(linkId));
  }

  observeRecordings(linkId: string, callback: LinkRecordingObserver): LinkRecordingUnsubscriber {
    return super.observeAll(FirestoreLinkRecordingRepository.getCollectionPath(linkId), callback);
  }

  private setupUploadProgressCallback(task: UploadTask, onProgressCallback: RecordingUploadProgressCallback) {
    const calculateProgress = (snapshot: UploadTaskSnapshot) => (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

    task.on('state_changed', (s: UploadTaskSnapshot) => onProgressCallback(calculateProgress(s)));
  }

  async uploadVideoFromUri(
    linkId: string,
    recordingId: string,
    uri: string,
    onProgressCallback: RecordingUploadProgressCallback,
  ): Promise<void> {
    const ref = this.storage.ref(FirestoreLinkRecordingRepository.getStorageRecordingPath(linkId, recordingId));

    if ('putFile' in ref) {
      const task = ref.putFile(uri);

      this.setupUploadProgressCallback(task, onProgressCallback);

      await task;
    }
  }

  async uploadVideoFromBytes(
    linkId: string,
    recordingId: string,
    data: Blob | Uint8Array | ArrayBuffer,
    onProgressCallback: RecordingUploadProgressCallback,
  ): Promise<void> {
    const task = this.storage
      .ref(FirestoreLinkRecordingRepository.getStorageRecordingPath(linkId, recordingId))
      .put(data);

    this.setupUploadProgressCallback(task, onProgressCallback);

    await task;
  }

  async deleteRecording(linkId: string, recordingId: string): Promise<void> {
    await this.storage.ref(FirestoreLinkRecordingRepository.getStorageRecordingPath(linkId, recordingId)).delete();
    await this.delete(FirestoreLinkRecordingRepository.getEntityPath(recordingId, linkId));
  }
}
