/* eslint-disable @next/next/no-img-element */
import {
  Dispatch,
  MutableRefObject,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import HLButton, { HLButtonProps } from "components/HLButton";
import { AnimatePresence, motion } from "framer-motion";
import localforage from "localforage";
import { PhotoData } from "services/photo.service";
import { RecorderStates, useRecorderState } from "state/useRecorderState";

import { Dimensions, TRANSITION_DURATION_NORMAL } from "./HLMain";

interface RecordIntroCardProps {
  title: string;
  subtitle: string;
  startImageContainerWidth: number;
  startImageContainerHeight: number;
  buttonProps: HLButtonProps;
  photoData: PhotoData | null; // context ?
  startSession: boolean; // context?
  recordControlsWrapperRef: RefObject<HTMLDivElement>;
  photoContainerRef: MutableRefObject<HTMLDivElement | null>;
  ghostStartImageContainerRef: RefObject<HTMLDivElement>;
  animationCompleted?: boolean; // context?
  setAnimationCompleted: Dispatch<SetStateAction<boolean>>; // context?
  setRecordControlsHeight: Dispatch<SetStateAction<number>>;
  mediaDeclined: boolean; // context?
  skipToFinishedState: () => void;
}

/**
 * The animation for this component works by calculcating the start (intro state) and end (recording state) image container dimensions and image scale so it knows where to animate from and to. There is also logic to handle resizing. *.
 */
const RecordIntroCard = ({
  title,
  subtitle,
  startImageContainerWidth,
  startImageContainerHeight,
  buttonProps,
  photoData,
  startSession,
  recordControlsWrapperRef,
  photoContainerRef,
  ghostStartImageContainerRef,
  animationCompleted,
  setAnimationCompleted,
  setRecordControlsHeight,
  mediaDeclined,
  skipToFinishedState,
}: RecordIntroCardProps) => {
  const { recorderState } = useRecorderState();
  const image = useRef<HTMLImageElement>(null);
  const recordControlsHeightRef = useRef<number>(0);
  const imageNaturalDimensionsRef = useRef<Dimensions>({
    width: null,
    height: null,
  });
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);
  const [imageRendered, setImageRendered] = useState<boolean>(false);
  const [startImageDimensions, setStartImageDimensions] = useState<Dimensions>({
    width: null,
    height: null,
  });
  const [endImageDimensions, setEndImageDimensions] = useState<Dimensions>({
    width: null,
    height: null,
  });

  const cardContainerVariants = {
    startContainerDimensions: {
      width: startImageContainerWidth,
      height: startImageContainerHeight,
      transition: {
        duration: TRANSITION_DURATION_NORMAL,
        delay: startSession ? 0 : TRANSITION_DURATION_NORMAL,
      },
    },
    endContainerDimensions: {
      width: endImageDimensions.width as number,
      height: endImageDimensions.height as number,
      transition: {
        duration:
          startSession && animationCompleted ? 0 : TRANSITION_DURATION_NORMAL,
        delay:
          startSession && animationCompleted ? 0 : TRANSITION_DURATION_NORMAL,
      },
    },
  };

  const imageVariants = {
    startImage: {
      transition: {
        duration: TRANSITION_DURATION_NORMAL,
        delay: startSession ? 0 : TRANSITION_DURATION_NORMAL,
      },
      width: startImageDimensions.width as number,
      height: startImageDimensions.height as number,
      opacity: imageRendered ? 1 : 0,
    },
    endImage: {
      transition: {
        duration:
          startSession && animationCompleted ? 0 : TRANSITION_DURATION_NORMAL,
        delay:
          startSession && animationCompleted ? 0 : TRANSITION_DURATION_NORMAL,
      },
      height: endImageDimensions.height as number,
      width: endImageDimensions.width as number,
      opacity: 1,
    },
  };

  const cardOverlayVariants = {
    show: {
      opacity: 0.6,
      transition: {
        duration: TRANSITION_DURATION_NORMAL,
        delay: TRANSITION_DURATION_NORMAL * 2,
      },
    },
    hide: {
      opacity: 0,
      transition: { duration: TRANSITION_DURATION_NORMAL },
    },
  };

  const cardContentVariants = {
    hide: { opacity: 0, transition: { duration: TRANSITION_DURATION_NORMAL } },
    show: {
      opacity: 1,
      transition: {
        duration: TRANSITION_DURATION_NORMAL,
        delay: TRANSITION_DURATION_NORMAL * 2,
      },
    },
    moveDown: { y: 20, transition: { duration: TRANSITION_DURATION_NORMAL } },
    moveUp: {
      y: 0,
      transition: {
        duration: TRANSITION_DURATION_NORMAL,
        delay: TRANSITION_DURATION_NORMAL * 2,
      },
    },
  };

  const handleImageRender = useCallback(() => {
    const startRender = () => {
      requestAnimationFrame(rendered);
    };
    const rendered = () => {
      setImageRendered(true);

      localforage.getItem("chunk-count").then((index) => {
        if (index) {
          skipToFinishedState();
        }
      });
    };
    requestAnimationFrame(startRender);
  }, [skipToFinishedState]);

  const handleImageLoad = useCallback(
    (imageEl: HTMLImageElement): void => {
      imageNaturalDimensionsRef.current.width = imageEl.naturalWidth;
      imageNaturalDimensionsRef.current.height = imageEl.naturalHeight;
      setImageLoaded(true);
      handleImageRender();
    },
    [handleImageRender]
  );

  const setRecordControlsHeightValue = useCallback(() => {
    const recordControlsEl = recordControlsWrapperRef.current;
    const recordControlsVisible =
      recordControlsEl && recordControlsEl.style.opacity === "1";
    const updatedRecordControlsHeight =
      (!recordControlsVisible && !startSession) || recordControlsEl === null
        ? 0
        : recordControlsEl.offsetHeight;
    if (recordControlsHeightRef.current !== updatedRecordControlsHeight)
      recordControlsHeightRef.current = updatedRecordControlsHeight;
    setRecordControlsHeight(updatedRecordControlsHeight);
  }, [recordControlsWrapperRef, startSession, setRecordControlsHeight]);

  /**
     * Caclulates the aspect ratios of the image and container to determine whether image is letter-boxed
       Letter-boxed - content takes up entire width, but not height
       ┌───────────────────────────────┐
       │┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼│
       ├───────────────────────────────┤
       │                               │
       │            Content            │
       │                               │
       ├───────────────────────────────┤
       │┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼│
       └───────────────────────────────┘
     *
     * @param {boolean} isIntro Whether you want the isLetterBoxed value for the intro container  or record container.
     * @return {boolean} image letter-boxed value.
     */
  const getLetterBoxedValue = useCallback((): boolean => {
    if (
      !ghostStartImageContainerRef.current ||
      !photoContainerRef.current ||
      !imageNaturalDimensionsRef.current
    ) {
      return false;
    }

    const containerWidth = !startSession
      ? ghostStartImageContainerRef.current.offsetWidth
      : photoContainerRef.current.offsetWidth;
    const containerHeight = !startSession
      ? ghostStartImageContainerRef.current.offsetHeight
      : photoContainerRef.current.offsetHeight -
        recordControlsHeightRef.current;
    const containerAspectRatio = containerWidth / containerHeight;
    const imageAspectRatio =
      (imageNaturalDimensionsRef.current.width as number) /
      (imageNaturalDimensionsRef.current.height as number);
    const isLetterBoxed = containerAspectRatio < imageAspectRatio;
    return isLetterBoxed;
  }, [ghostStartImageContainerRef, photoContainerRef, startSession]);

  const calculateImageDimensions = useCallback(
    (containerWidth: number, containerHeight: number) => {
      const imageWidth = imageNaturalDimensionsRef.current.width as number;
      const imageHeight = imageNaturalDimensionsRef.current.height as number;
      const heightScale = containerHeight / imageHeight;
      const widthScale = containerWidth / imageWidth;
      const isLetterBoxed = getLetterBoxedValue();
      const scale = !startSession
        ? isLetterBoxed
          ? heightScale
          : widthScale
        : isLetterBoxed
        ? widthScale
        : heightScale;

      const height = imageHeight * scale;
      const width = imageWidth * scale;
      return { width, height };
    },
    [getLetterBoxedValue, startSession]
  );

  const setStartValues = useCallback((): void => {
    if (!ghostStartImageContainerRef.current) {
      return;
    }

    const imageContainerWidth = ghostStartImageContainerRef.current.offsetWidth;
    const imageContainerHeight =
      ghostStartImageContainerRef.current.offsetHeight;

    const { width, height } = calculateImageDimensions(
      imageContainerWidth,
      imageContainerHeight
    );

    setStartImageDimensions({ width, height });
  }, [calculateImageDimensions, ghostStartImageContainerRef]);

  const setEndValues = useCallback((): void => {
    if (!photoContainerRef.current) {
      return;
    }

    const containerWidth = photoContainerRef.current.offsetWidth;
    const containerHeight =
      photoContainerRef.current.offsetHeight - recordControlsHeightRef.current;
    const { width, height } = calculateImageDimensions(
      containerWidth,
      containerHeight
    );

    setEndImageDimensions({
      width,
      height,
    });
  }, [calculateImageDimensions, photoContainerRef]);

  const calculateValuesForAnimation = useCallback((): void => {
    setRecordControlsHeightValue();
    setStartValues();
    setEndValues();
  }, [setRecordControlsHeightValue, setStartValues, setEndValues]);

  const handleAnimationComplete = useCallback(() => {
    // preventCallback flag needed, otherwise this function will run everytime the image resizes in the record state.
    const preventCallback = startSession && animationCompleted;
    if (preventCallback) return;
    setAnimationCompleted(!animationCompleted);
    if (mediaDeclined) {
      location.reload();
    }
  }, [startSession, animationCompleted, setAnimationCompleted, mediaDeclined]);

  useEffect(() => {
    if (imageLoaded) {
      calculateValuesForAnimation();
      window.addEventListener("resize", calculateValuesForAnimation);
    }
    return () =>
      window.removeEventListener("resize", calculateValuesForAnimation);
  }, [imageLoaded, startSession, calculateValuesForAnimation]);

  useEffect(() => {
    if (photoData && image.current && image.current.complete) {
      handleImageLoad(image.current);
    }
  }, [photoData, handleImageLoad]);

  return (
    <motion.div
      className="rounded-3xl max-h-full max-w-full relative flex justify-center items-center  border border-solid border-spruce-60-20 overflow-hidden"
      style={{
        // needed for border radius in safari
        transform: "translateZ(0)",
      }}
      variants={cardContainerVariants}
      initial={false}
      animate={
        startSession ? "endContainerDimensions" : "startContainerDimensions"
      }
      onAnimationComplete={handleAnimationComplete}
    >
      {photoData ? (
        <motion.img
          ref={image}
          onLoad={(e) => {
            handleImageLoad(e.currentTarget);
          }}
          className="max-w-none"
          // default to original photo URL if srcset is not supported (unlikely, support was added in 2014)
          src={photoData.urls[photoData.urls.length - 1]}
          srcSet={photoData.srcset.join(",")}
          sizes={photoData.sizes.join(",")}
          variants={imageVariants}
          style={{
            height: startImageDimensions.height as number,
            width: startImageDimensions.width as number,
          }}
          animate={startSession ? "endImage" : "startImage"}
          alt="Heirloom Photo Story Image"
        />
      ) : null}

      <AnimatePresence initial={false}>
        {recorderState === RecorderStates.UPLOADING || !startSession ? (
          <motion.div
            key="overlay"
            className="absolute w-full h-full bg-spruce-15 opacity-60"
            variants={cardOverlayVariants}
            initial="hide"
            animate="show"
            exit="hide"
          />
        ) : null}
        {imageRendered &&
        (recorderState === RecorderStates.UPLOADING || !startSession) ? (
          <motion.div
            key="overlay-gradient"
            className={`w-full absolute pt-32 pb-4 px-4 bottom-0 ${
              recorderState === RecorderStates.UPLOADING
                ? ""
                : "overlay-gradient"
            }`}
            variants={cardContentVariants}
            initial={["hide", "moveDown"]}
            animate={["show", "moveUp"]}
            exit={["hide", "moveDown"]}
          >
            <div>
              <img className="mb-2" src="/wordmark.svg" alt="Heirloom" />
              <h1 className="text-4xl font-semibold font-source text-yellow-93 mb-4">
                {title}
              </h1>
              <p
                className="text-2xl font-source text-spruce-60 mb-6"
                id="record-intro-card-subtitle"
              >
                {recorderState === RecorderStates.UPLOADING ? "" : subtitle}
              </p>
            </div>
            {recorderState !== RecorderStates.UPLOADING && (
              <HLButton {...buttonProps} />
            )}
          </motion.div>
        ) : null}
      </AnimatePresence>
    </motion.div>
  );
};

export default RecordIntroCard;
