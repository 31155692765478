import { useEffect, useRef } from "react";
import {
  AnalyticsService,
  Events,
  useAnalytics,
  useAnalyticsTimer,
} from "services/analytics.service";
import { RecorderStates, useRecorderState } from "state/useRecorderState";
import { formatDuration } from "utils/formatDuration";

export function useRecordAndUploadAnalytics() {
  let startRecorderTime = useRef<number>(0);
  let startUploadTime = useRef<number>(0);
  const { recorderState } = useRecorderState();
  const analytics = useAnalytics() as AnalyticsService;
  const [startRecordingAnalyticsTimer, stopRecordingAnalyticsTimer] =
    useAnalyticsTimer("seconds");
  const [startUploadAnalyticsDuration, stopUploadAnalyticsDuration] =
    useAnalyticsTimer();

  const initRecordAndUploadAnalytics = () => {
    switch (recorderState) {
      case RecorderStates.RECORDING:
        startRecorderTime.current = startRecordingAnalyticsTimer();
        break;
      case RecorderStates.FINISHED:
        const recordingExists = startRecorderTime.current === 0;
        if (recordingExists) return;
        const duration = stopRecordingAnalyticsTimer(startRecorderTime.current);
        const formattedDuration = formatDuration(duration, "SHORT");
        analytics.track(Events.recordEnd, {
          recordingLength: formattedDuration,
        });
        break;
      case RecorderStates.UPLOADING:
        startUploadTime.current = startUploadAnalyticsDuration();
        break;
      case RecorderStates.UPLOADED:
        const uploadDuration = stopUploadAnalyticsDuration(
          startUploadTime.current
        );
        analytics.track(Events.upload, { uploadDurationMs: uploadDuration });
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    initRecordAndUploadAnalytics();
  }, [recorderState]);
}
