import HLButton, { HLButtonProps } from "components/HLButton";
import { PhotoData } from "services/photo.service";

interface RecordIntroCardProps {
  title: string;
  subtitle: string;
  containerWidth: number;
  containerHeight: number;
  buttonProps: HLButtonProps;
  photoData: PhotoData | null;
}

/**
 * Non-animated version of `RecordIntroCard` component*.
 */
const RecordIntroCardBasic = ({
  title,
  subtitle,
  photoData,
  buttonProps,
  containerWidth,
  containerHeight,
}: RecordIntroCardProps) => {
  return (
    <div
      className="rounded-3xl max-h-full max-w-full relative flex justify-center items-center border border-solid border-spruce-60-20 overflow-hidden"
      style={{ height: containerHeight, width: containerWidth }}
    >
      {photoData ? (
        <img
          className="object-cover w-full h-full"
          // default to original photo URL if srcset is not supported (unlikely, support was added in 2014)
          src={photoData.urls[photoData.urls.length - 1]}
          srcSet={photoData.srcset.join(",")}
          sizes={photoData.sizes.join(",")}
          alt="Heirloom Photo Story Image"
        />
      ) : null}
      <div className="absolute w-full h-full bg-spruce-15 opacity-60" />
      <div className="w-full absolute pt-32 pb-4 px-4 bottom-0 overlay-gradient">
        <div>
          <img className="mb-2" src="/wordmark.svg" alt="Heirloom" />
          <h1 className="text-4xl font-semibold font-source text-yellow-93 mb-4">
            {title}
          </h1>
          <p className="text-2xl font-source text-spruce-60 mb-6">{subtitle}</p>
        </div>
        <HLButton {...buttonProps} />
      </div>
    </div>
  );
};

export default RecordIntroCardBasic;
