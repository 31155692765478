import { useCallback } from "react";
import { useEffect, useRef, useState } from "react";

export function useTimer(
  endTimeSeconds: number,
  onTimerEndCallback: () => unknown
) {
  const interval = useRef<NodeJS.Timeout | null>(null);
  const [duration, setDuration] = useState<number>(0);
  const [startTimer, setStartTimer] = useState<boolean>(false);

  useEffect(() => {
    if (startTimer) {
      interval.current = setInterval(() => {
        setDuration((prev) => prev + 1);
      }, 1000);
    } else if (interval.current) {
      clearInterval(interval.current);
    }

    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, [startTimer]);

  useEffect(() => {
    if (duration === endTimeSeconds && interval.current) {
      onTimerEndCallback();
      clearInterval(interval.current);
      return;
    }
  }, [duration, endTimeSeconds]);

  const reset = useCallback((): void => {
    if (interval.current) {
      clearInterval(interval.current);
      setDuration(0);
    }
  }, []);

  const start = useCallback((): void => {
    reset();
    setStartTimer(true);
  }, [reset]);

  const stop = useCallback((): number => {
    setStartTimer(false);
    return duration;
  }, [duration]);

  return { start, stop, reset, duration };
}
