import { blobRepo } from "heirloom-api/BlobRepo";
import { RecorderStates, useRecorderState } from "state/useRecorderState";

export const createMediaRecorder = (stream: MediaStream, mimeType: string) => {
  const { setRecorderState } = useRecorderState.getState();

  const recorder = new MediaRecorder(stream, {
    videoBitsPerSecond: 2.5 * 1_000_000,
    mimeType,
  });

  recorder.ondataavailable = async (event) => {
    if (
      !blobRepo.addBlobChunk(new Uint8Array(await event.data.arrayBuffer()))
    ) {
      recorder.stop();
      alert(
        "Your device has run out of storage. Please clear some space before continuing."
      );
    }
  };

  recorder.onstop = async () => {
    setRecorderState(RecorderStates.FINISHED);
  };

  return recorder;
};
