import {
  Document,
  FirestoreDocumentSnapshot,
  FirestoreInstance,
  FirestoreObserver,
  FirestoreUnsubscriber,
} from '../providers/firebase';

export class FirestoreRepository<T extends Document> {
  constructor(private firestore: FirestoreInstance) {
    this.firestore = firestore;
  }

  static deserializeEntity<T>(document: FirestoreDocumentSnapshot<T>): T {
    return {
      ...document.data(),
      id: document.id,
    };
  }

  static serializeEntity<T extends Document>(entity: T): T {
    const entityWithoutId = { ...entity };
    delete entityWithoutId.id;
    return entityWithoutId;
  }

  async create(path: string, obj: T): Promise<T> {
    if (obj.id != null) {
      throw new Error('The entity being created already has an ID');
    }
    const document = await this.firestore.collection<T>(path).add(FirestoreRepository.serializeEntity(obj));
    return {
      ...obj,
      id: document.id,
    };
  }

  async get(path: string): Promise<T> {
    const document = await this.firestore.doc<T>(path).get();
    if (document == null) throw new Error(`document with path ${path} does not exist`);
    return FirestoreRepository.deserializeEntity(document);
  }

  async getAll(path: string): Promise<T[]> {
    const collection = await this.firestore.collection<T>(path).get();
    return collection.docs.map<T>(FirestoreRepository.deserializeEntity);
  }

  observe(path: string, observer: FirestoreObserver<T>): FirestoreUnsubscriber {
    return this.firestore
      .doc<T>(path)
      .onSnapshot((document) => observer(FirestoreRepository.deserializeEntity(document)));
  }

  observeAll(path: string, observer: FirestoreObserver<T[]>): FirestoreUnsubscriber {
    return this.firestore
      .collection<T>(path)
      .onSnapshot((collection) =>
        observer(collection.docs.map((document) => FirestoreRepository.deserializeEntity(document))),
      );
  }

  async update(path: string, obj: T): Promise<T> {
    if (obj.id == null) {
      throw Error('The entity being updated does not have an ID');
    }
    await this.firestore.doc<T>(path).update(FirestoreRepository.serializeEntity(obj));

    return obj;
  }

  async delete(path: string): Promise<void> {
    await this.firestore.doc<T>(path).delete();
  }
}
