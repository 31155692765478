import { formatDuration } from "utils/formatDuration";

interface RecordingDurationProps {
  isRecording: boolean;
  duration: number;
}

export function RecordingDuration({
  isRecording,
  duration,
}: RecordingDurationProps) {
  const formattedDuration = formatDuration(duration, "SHORT");
  return (
    <div className="flex items-center justify-center bg-spruce-15 absolute bottom-1 left-1 rounded-3xl py-1 px-3">
      {isRecording && (
        <span className="inline-block rounded-full mr-2 h-3 w-3 bg-red-20 border border-red-40 animate-pulse-indicator direction-alternate" />
      )}

      <span className="text-spruce-60 font-inter">{formattedDuration}</span>
    </div>
  );
}
