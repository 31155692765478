/* eslint-disable @next/next/no-img-element */
import { PropsWithChildren, useEffect, useRef, useState } from "react";
import { useCallback } from "react";
import HLButton from "components/HLButton";
import PictureInPicture from "components/PictureInPicture";
import RecordControls from "components/RecordControls";
import RecordIntroCard from "components/RecordIntroCard";
import RecordIntroCardBasic from "components/RecordIntroCardBasic";
import { AnimatePresence, motion } from "framer-motion";
import { blobRepo } from "heirloom-api/BlobRepo";
import { linkRecordingRepo } from "heirloom-api/LinkRecordingRepo";
import { useRecordAndUploadAnalytics } from "hooks/useRecordAndUploadAnalytics";
import { useTimer } from "hooks/useTimer";
import { useTranslation } from "next-i18next";
import { FirebaseLinkErrors } from "pages/[[...linkId]]";
import { PhotoData } from "services/photo.service";
import { RecorderStates, useRecorderState } from "state/useRecorderState";

const RECORDING_LIMIT_SECONDS = 300;

const INITIAL_IMAGE_CONTAINER_WIDTH = 600;
const INITIAL_IMAGE_CONTAINER_HEIGHT = 700;

export const TRANSITION_DURATION_SLOW = 0.6;
export const TRANSITION_DURATION_NORMAL = 0.3;
export const TRANSITION_DURATION_FAST = 0.15;

type HLMainProps = PropsWithChildren<{
  photoData: PhotoData | null;
  linkId: string | null;
  error: { message: string; code: string } | null;
  videoMimeType: string;
  serviceWorkerActive: boolean;
}>;

export interface Dimensions {
  width: number | null;
  height: number | null;
}

const HLMain = ({
  photoData,
  error,
  linkId,
  videoMimeType,
  serviceWorkerActive,
}: HLMainProps) => {
  // Animation Variants
  const recordControlsVariants = {
    hide: {
      opacity: 0,
      y: 10,
      transition: {
        duration: TRANSITION_DURATION_NORMAL,
      },
    },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: TRANSITION_DURATION_NORMAL,
        delay: TRANSITION_DURATION_NORMAL * 2,
      },
    },
  };

  const introCardWrapperVariants = {
    // animates RecordIntroCard to appropriate position when transitioning to Record screen, accounting for the height of the record controls
    positionRecord: (recordControlsHeight: number) => ({
      paddingBottom: recordControlsHeight,
      transition: {
        duration: TRANSITION_DURATION_NORMAL,
        delay: TRANSITION_DURATION_NORMAL,
      },
    }),
    positionIntro: {
      paddingBottom: 0,
      transition: {
        duration: TRANSITION_DURATION_NORMAL,
        delay: TRANSITION_DURATION_NORMAL,
      },
    },
  };

  const thankYouPageVariants = {
    hide: {
      opacity: 0,
      y: 10,
      transition: {
        duration: TRANSITION_DURATION_NORMAL,
      },
    },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: TRANSITION_DURATION_NORMAL,
        delay: TRANSITION_DURATION_SLOW,
      },
    },
  };
  useRecordAndUploadAnalytics();
  const { recorderState, setRecorderState } = useRecorderState();
  const { t } = useTranslation("common");
  const { start, stop, reset, duration } = useTimer(
    RECORDING_LIMIT_SECONDS,
    () => {
      (videoRecorderRef.current as MediaRecorder).stop();
    }
  );

  const recordControlsWrapperRef = useRef<HTMLDivElement>(null);
  const photoContainerRef = useRef<HTMLDivElement>(null);
  const videoRecorderRef = useRef<MediaRecorder>(null);
  /*  
    ghostStartImageContainerRef is to keep track of the intro card dimensions while in the record state.
    Since we're using "scale" to animate the image relative to the container dimensions,
    we want to keep track of the intro card size in the case a user resizes the window while recording, otherwise,
    the image scale will be off as it will animate back to the calculated scale based off the original intro card dimensions
    */
  const ghostStartImageContainerRef = useRef<HTMLDivElement>(null);

  const [userMediaStream, setUserMediaStream] = useState<MediaStream | null>(
    null
  );
  const [cameraReady, setCameraReady] = useState<boolean>(false);
  const [recordControlsHeight, setRecordControlsHeight] = useState<number>(0);
  const [startSession, setStartSession] = useState<boolean>(false);
  const [animationCompleted, setAnimationCompleted] = useState<boolean>(false);
  const [previewURL, setPreviewURL] = useState<string | null>(null);
  const [mediaDeclined, setMediaDeclined] = useState(false);

  // this sets all necessary variables to skip directly to the `FINISHED` state
  // this is useful in case the user's visiting the page with an already-recorded video
  const skipToFinishedState = useCallback(() => {
    setStartSession(true);
    setRecorderState(RecorderStates.FINISHED);
    setAnimationCompleted(true);
    setCameraReady(true);
  }, [setRecorderState]);

  useEffect(() => {
    const shouldUpdateUserMediaStream =
      (recorderState === RecorderStates.READY ||
        recorderState === RecorderStates.STARTING) &&
      userMediaStream === null;

    if (
      startSession === true &&
      animationCompleted &&
      shouldUpdateUserMediaStream
    ) {
      navigator.mediaDevices
        .getUserMedia({
          audio: true,
          video: {
            frameRate: 30,
          },
        })
        .then((stream) => {
          setUserMediaStream(stream);
        })
        .catch((err) => {
          setMediaDeclined(true);
          setStartSession(false);
        });
    }
  }, [startSession, animationCompleted, recorderState, userMediaStream]);

  // uncomment the below block to test out storage capaacities
  //
  // useEffect(() => {
  //   (async () => {
  //     await blobRepo.clearBlob();
  //     await blobRepo.initialize();
  //     const data = new Uint8Array(1024 * 1024);
  //     // Just to be safe, we'll fill up the array
  //     for (let i = 0; i < data.length; i++) {
  //       data[i] = i % 8;
  //     }
  //     for (let i = 0; i < 995; i++) {
  //       await localforage.setItem(`tmp-${i}`, data);
  //     }
  //     console.log('The storage is populated');
  //   })();
  // }, []);

  useEffect(() => {
    async function assignPreviewURL() {

      // wait for the last write to complete to make sure we've saved all videos to localforage
      await blobRepo.wait();
      const url = await blobRepo.getBlobUrl();
      setPreviewURL(url);
    }

    if (recorderState === RecorderStates.FINISHED && serviceWorkerActive) {
      assignPreviewURL();
    }
  }, [recorderState, serviceWorkerActive]);
  return (
    <>
      <main className="h-full flex flex-col items-center justify-center p-4 md:p-8 lg:p-14 ">
        <motion.section
          className={`h-full w-full flex flex-col items-center justify-center`}
          custom={recordControlsHeight}
          variants={introCardWrapperVariants}
          animate={startSession ? "positionRecord" : "positionIntro"}
          ref={photoContainerRef}
        >
          <div
            className={`flex max-h-full max-w-full justify-center items-center ${
              recorderState === RecorderStates.UPLOADING ? "relative" : ""
            }`}
          >
            {error ? (
              <RecordIntroCardBasic
                title={t("web-rec-intro-title")}
                subtitle={FirebaseLinkErrors[error.code]}
                containerWidth={INITIAL_IMAGE_CONTAINER_WIDTH}
                containerHeight={INITIAL_IMAGE_CONTAINER_HEIGHT}
                buttonProps={{
                  title: "Sign Up for Early Access",
                  highEmphasis: false,
                  onClick: () =>
                    window.location.assign("https://tryheirloom.com/"),
                  highBackgroundContrast: true,
                  buttonAnalyticsId: "sign-up-btn",
                }}
                photoData={photoData}
              />
            ) : (
              <AnimatePresence exitBeforeEnter initial={false}>
                {recorderState !== RecorderStates.UPLOADED ? (
                  <>
                    <RecordIntroCard
                      key="intro-card"
                      startImageContainerHeight={INITIAL_IMAGE_CONTAINER_HEIGHT}
                      startImageContainerWidth={INITIAL_IMAGE_CONTAINER_WIDTH}
                      photoData={photoData}
                      title={t("web-rec-intro-title")}
                      subtitle={
                        mediaDeclined
                          ? "Please enable camera and microphone access to share your story."
                          : t("web-rec-intro-description")
                      }
                      buttonProps={{
                        title: t("web-rec-intro-action-primary"),
                        highEmphasis: true,
                        onClick: () => setStartSession(true),
                        disabled: mediaDeclined,
                        buttonAnalyticsId: "get-started-btn",
                        ariaAttributes: {
                          "aria-label":
                            "Press Enter to get started with recording your Photo Story",
                          "aria-describedby": "record-intro-card-subtitle",
                        },
                      }}
                      startSession={startSession}
                      animationCompleted={animationCompleted}
                      ghostStartImageContainerRef={ghostStartImageContainerRef}
                      recordControlsWrapperRef={recordControlsWrapperRef}
                      photoContainerRef={photoContainerRef}
                      setAnimationCompleted={setAnimationCompleted}
                      mediaDeclined={mediaDeclined}
                      setRecordControlsHeight={setRecordControlsHeight}
                      skipToFinishedState={skipToFinishedState}
                    />
                    <PictureInPicture
                      startSession={startSession}
                      animationCompleted={animationCompleted}
                      userMediaStream={userMediaStream}
                      setUserMediaStream={setUserMediaStream}
                      previewURL={previewURL}
                      cameraReady={cameraReady}
                      setCameraReady={setCameraReady}
                      duration={duration}
                    />
                  </>
                ) : (
                  <motion.div
                    key="thank-you-page"
                    variants={thankYouPageVariants}
                    initial="hide"
                    animate="show"
                  >
                    <img className="mb-4" src="/wordmark.svg" alt="Heirloom" />
                    <h1 className="text-yellow-93 text-4xl font-source mb-6">
                      {t("web-upload-success-title")}
                    </h1>
                    <p className="text-spruce-60 font-inter mb-4">
                      {t("web-upload-success-description")}
                    </p>

                    <HLButton
                      title={t("web-upload-success-action-secondary")}
                      onClick={() =>
                        location.assign("https://tryheirloom.com/welcome")
                      }
                      highBackgroundContrast
                      buttonAnalyticsId="sign-up-for-early-access-btn"
                      ariaAttributes={{
                        "aria-describedby": "thanks-header",
                      }}
                    />
                  </motion.div>
                )}
              </AnimatePresence>
            )}
          </div>
        </motion.section>
        <AnimatePresence initial={false}>
          {startSession ? (
            <motion.section
              key="record-controls"
              className="absolute bottom-4 md:bottom-8 lg:bottom-14 w-full flex justify-center px-4 md:px-8 lg:px-14"
              ref={recordControlsWrapperRef}
              variants={recordControlsVariants}
              initial="hide"
              animate="show"
              exit="hide"
            >
              <RecordControls
                userMediaStream={userMediaStream}
                setUserMediaStream={setUserMediaStream}
                previewURL={previewURL}
                setStartSession={setStartSession}
                linkId={linkId as string}
                linkRecordingRepo={linkRecordingRepo}
                cameraReady={cameraReady}
                startRecordingDurationTimer={start}
                stopRecordingDurationTimer={stop}
                resetRecordingDurationTimer={reset}
                recordingLimitSec={RECORDING_LIMIT_SECONDS}
                duration={duration}
                videoRecorderRef={videoRecorderRef}
                videoMimeType={videoMimeType}
              />
            </motion.section>
          ) : null}
        </AnimatePresence>
      </main>
      <div
        ref={ghostStartImageContainerRef}
        className="max-w-full max-h-full z-[-100] opacity-0 absolute top-0"
        style={{
          width: INITIAL_IMAGE_CONTAINER_WIDTH,
          height: INITIAL_IMAGE_CONTAINER_HEIGHT,
        }}
      />
    </>
  );
};

export default HLMain;
