/* eslint-disable @next/next/no-img-element */
import { PropsWithChildren, useEffect, useState } from "react";
import HLMain from "components/HLMain";
import { blobRepo } from "heirloom-api/BlobRepo";
import { linkRepo } from "heirloom-api/LinkRepo";
import { GetServerSideProps, NextPage } from "next";
import Head from "next/head";
import Script from "next/script";
import { SSRConfig, useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import semver from "semver";
import { createPhotoData, PhotoData } from "services/photo.service";
import { RecorderStates, useRecorderState } from "state/useRecorderState";
import UAParser from "ua-parser-js";

interface FirebaseError extends Error {
  code: string;
}

interface FirebaseLinkErrors {
  [key: string]: string;
}
interface ServerError {
  message: string;
  code: string;
}

type HomePageProps = PropsWithChildren<{
  photoData: PhotoData | null;
  linkId: string | null;
  error: ServerError | null;
  requestURL: string | null;
}>;

export const FirebaseLinkErrors: FirebaseLinkErrors = {
  "storage/unknown": "Recording is currently unavailable.",
  "storage/object-not-found": "This recording link does not exist.",
  "firestore/link-disabled": "This recording link is no longer available.",
  "browser/unsupported":
    "This web browser is not supported. Please open this link on another device or web browser.",
  "browser/ios-under-15":
    "To record a Photo Story, your Apple device needs to be updated to iOS 15 or newer.",
};

const RecordPage: NextPage<HomePageProps> = ({
  photoData,
  error: serverError,
  linkId,
  requestURL,
}: HomePageProps) => {
  const { t } = useTranslation("common");
  const [videoMimeType, setVideoMimeType] = useState<string>("");
  const [serviceWorkerActive, setServiceWorkerActive] =
    useState<boolean>(false);
  const [error, setError] = useState<ServerError | null>(serverError);
  const { recorderState, setRecorderState } = useRecorderState();

  useEffect(() => {
    // Detect the browser's capabilities before we get going

    if (serverError != null) {
      setRecorderState(RecorderStates.READY);
      return;
    }

    // Check if the browser has the necessary apis
    const isUserAgentMissing =
      typeof window == "undefined" || window?.navigator?.userAgent == null;
    const areNecessaryBrowserAPIsMissing =
      !("indexedDB" in window) ||
      !("MediaRecorder" in window) ||
      !("mediaDevices" in navigator) ||
      !("serviceWorker" in navigator);
    if (isUserAgentMissing || areNecessaryBrowserAPIsMissing) {
      setError({
        message: "missing apis",
        code: "browser/unsupported",
      });
      return;
    }

    // Check if browser supports one of the listed mime types
    const fullMimeType = [
      "video/mp4;codecs=h264",
      "video/mp4;codecs:h264",
      "video/webm;codecs=h264",
      "video/webm;codecs:h264",
    ].find((mime) => MediaRecorder.isTypeSupported(mime));
    if (fullMimeType === undefined) {
      setError({
        message: "",
        code: "browser/unsupported",
      });
      setRecorderState(RecorderStates.READY);
      return;
    } else {
      const [mimeType, codec] = fullMimeType.split(";");
      setVideoMimeType(mimeType);
    }

    // we need to make versions confirm to semver before comparing them
    // we're doing this by adding `.0` until we have 2 decimals in the version number
    // this will, for ex., convert `15` to `15.0.0`, and `15.1` to `15.1.0`.
    const createSemverVersion = (version: string) => {
      // if `version` is undefined in the user agent string, return a valid string so the comparison doesn't fail
      if (!version) {
        return "0.0.0";
      }

      const decimalPlaces = version
        .split("")
        .filter((char) => char === ".").length;

      const verisonsToAdd = 2 - decimalPlaces;

      return `${version}${".0".repeat(verisonsToAdd)}`;
    };

    const parser = new UAParser(window.navigator.userAgent);
    const result = parser.getResult();

    // Check if the OS is iOS and browser is less than Safari 15.
    const isIosAndLessThanSafari15 =
      result.os?.name === "iOS" &&
      semver.lt(createSemverVersion(result.os.version as string), "15.0.0");
    if (!result.os) {
      setError({
        message: "",
        code: "browser/unsupported",
      });
      return;
    }

    if (isIosAndLessThanSafari15) {
      setError({
        message: "ios-under-15",
        code: "browser/unsupported",
      });
      return;
    }
    setRecorderState(RecorderStates.READY);
  }, [serverError, setRecorderState]);

  useEffect(() => {
    if (recorderState == RecorderStates.READY && error == null) {
      blobRepo.initialize().then(setServiceWorkerActive);
    }
  }, [recorderState, error]);

  return (
    <div className="bg-spruce-10 h-full max-h-full overflow-hidden relative">
      <Head>
        {photoData ? (
          <>
            <meta property="og:title" content={t("web-meta-title")} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={requestURL as string} />
            {/* The last URL in photoData.urls is our mobile-size photo */}
            <meta
              property="og:image"
              content={photoData.urls[photoData.urls.length - 1]}
            />
            <meta property="og:image:type" content="image/jpeg" />
            <meta name="twitter:title" content={t("web-meta-title")} />
            <meta
              name="twitter:description"
              content={t("web-meta-description")}
            />
            <meta
              name="twitter:image"
              content={photoData.urls[photoData.urls.length - 1]}
            />
            <meta name="twitter:card" content="summary_large_image" />
          </>
        ) : null}
        <title>Heirloom Photo Story</title>
        <meta name="description" content={t("web-meta-description")}></meta>
        <meta name="viewport" content="width=device-width"></meta>
        <link rel="icon" href="/favicon.png" />
      </Head>
      <Script
        id="segment-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="lpsge7IWblIQ4nSEQeBHosaXx8QhkvaX";;analytics.SNIPPET_VERSION="4.15.3";
              analytics.load("${process.env.NEXT_PUBLIC_SEGMENT_ANALYTICS_WRITE_KEY}");
              analytics.page();
              }}();`,
        }}
      />
      <HLMain
        photoData={photoData}
        linkId={linkId}
        error={error}
        videoMimeType={videoMimeType}
        serviceWorkerActive={serviceWorkerActive}
      />
    </div>
  );
};

type ServerSideData = {
  props: HomePageProps & SSRConfig;
};

function isFirebaseError(err: any): err is FirebaseError {
  return "code" in err;
}

export const getServerSideProps: GetServerSideProps = async ({
  params,
  req,
  locale,
}) => {
  const data: ServerSideData = {
    props: {
      photoData: null,
      linkId: null,
      error: null,
      requestURL: null,
      ...(await serverSideTranslations(locale as string, ["common"])),
    },
  };
  data.props.requestURL = `https://${req.headers.host}${req.url}`;

  try {
    const linkId = params?.linkId as string;
    const originalPhotoURL = await linkRepo.getPhotoUrl(linkId);

    const link = await linkRepo.getLink(linkId);
    if (!link.isActive) {
      throw {
        message: "This recording link is no longer available.",
        code: "firestore/link-disabled",
      };
    }

    data.props.photoData = createPhotoData(originalPhotoURL);
    data.props.linkId = linkId;
  } catch (err) {
    console.debug(err);
    if (isFirebaseError(err)) {
      data.props.error = { message: err.message, code: err.code };
    }
  }
  return data;
};

export default RecordPage;
