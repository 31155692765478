import { useEffect, useState } from "react";
import { motion, Variants } from "framer-motion";
import { RecorderStates, useRecorderState } from "state/useRecorderState";

interface RecordCountdownProps {
  variants: Variants;
}

export function RecordCountdown({ variants }: RecordCountdownProps) {
  const [countdownRejection, setCountdownRejection] = useState<Function>();
  const [countdownIntervalID, setCountdownIntervalID] =
    useState<NodeJS.Timer | null>(null);
  const [countdownTimer, setCountdownTimer] = useState(3);

  const { recorderState, setRecorderState } = useRecorderState();

  const doCountdown = () =>
    new Promise((resolve, reject) => {
      setCountdownRejection(() => reject);

      let newTimerValue = countdownTimer;

      const countdownIntervalID = setInterval(() => {
        setCountdownTimer((timer) => {
          newTimerValue = timer - 1;

          return newTimerValue;
        });

        if (newTimerValue === 0) {
          clearInterval(countdownIntervalID);
          setRecorderState(RecorderStates.RECORDING);
          resolve(true);
        }
      }, 1000);

      setCountdownIntervalID(countdownIntervalID);
    });

    useEffect(() => {
      doCountdown().catch(e => {
        // the countdown was canceled, do nothing
      });
    }, []);

  useEffect(() => {
    if (
      countdownIntervalID !== null &&
      recorderState !== RecorderStates.STARTING
    ) {
      clearInterval(countdownIntervalID);
      if (countdownRejection) {
        countdownRejection();
      }
    }
  }, [countdownIntervalID, recorderState, countdownRejection]);

  return (
    <motion.div
      className="absolute top-0 left-0 h-full w-full bg-spruce-10-60 flex items-center justify-center z-30 max-h-full rounded-2xl"
      variants={variants}
      initial="hideOverlay"
      animate="showOverlay"
      exit="hideOverlay"
    >
      <div
        className="text-white text-5xl font-semibold font-inter"
        role="timer"
        aria-atomic="true"
        aria-live="assertive"
      >
        {countdownTimer > 0 ? countdownTimer : ""}
      </div>
    </motion.div>
  );
}
